import React from "react"

/*** COMPONENTS ***/
import Main from "../../components/main"
import SEO from "../../components/seo"
import "../../styles/project.css"
import gdkHeader from "../../images/gdk.png"

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes"

function openGithubTetris() {
  window.open(
    "https://github.com/johannagranstroem/TNM108-Machine-Learning-for-Social-Media",
    "_blank"
  )
}

const graphicDesignPage = () => (
  <Main>
    <SEO title="Graphics Design" />
    <div class="project-container">
      <div class="project-grid-item-11 ">
        <img alt="Header-Tetris" src={gdkHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">POP-IP EVENT</p>
          <p class="project-header-text-grey">DESIGN</p>
        </div>
      </div>

      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">
          Graphics Design and Communication
        </p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2019-12-18</p>
      </div>

      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          This project involved creating a digital and/or printed marketing
          campaign for a pop-up event for the non-profit Engineers Without
          Borders Sweden (EWBS). The purpose of the event was to raise awareness
          about EWBS work and projects around the world, improving citizen
          infrastructure, while recruiting students to participate in their
          international projects. We were to identify a target audience and work
          accordingly to EWBS graphical profile.
          <br />
          <br />
          My group members and I wanted to create an interactive environment to
          attract audience attention. The idea was then to let the audience to
          take their time to read about different projects and the information
          presented.
          <br />
          <br />
          From this project, I learned more about graphic design and how to
          present information for an audience in an appealing way.
          <br />
          <br />
          You can read more about the project{" "}
          <button
            class="project-text-link-style"
            onClick={() => openGithubTetris()}
          >
            here
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
)

export default graphicDesignPage
